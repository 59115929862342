import Helmet from "react-helmet"
import React from "react"
import HoneyPage from "src/components/honeylong"
import HomepageLayout from "src/layouts/homepage"
import NonSearchDisclosure from "src/components/nonsearchdisclosure"
import CtaDisclosure from "@tightrope/ctadisclosure"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"


const homepageData = import("./data.json");

export default function HomePage() {
  let disclosure = null;
  if(isChrome()){
    disclosure = <NonSearchDisclosure data={homepageData}></NonSearchDisclosure>;
  }
  else{
    disclosure = <CtaDisclosure data={homepageData}></CtaDisclosure>;
  }
  return(
    <HomepageLayout>
      <Helmet>
      <style type="text/css">{`

        body {
          overflow-x: hidden;
        }
        #nonsearch-module--nonsearchdisclosure .nonsearch-module--disclosure p {
          color: black;
          text-align: left;
        }

        #nonsearch-module--nonsearchdisclosure .nonsearch-module--disclosure {
          padding: 0px;
        }

          #ctadisclosurenewtab-module--ctadisclaimer, #ctadisclosure-module--ctadisclaimer {
            font-weight: 300;
            color: #7d7d7d;
            text-align: left;
            padding: 24px 0px 0px;
          }

          #ctadisclosurenewtab-module--ctadisclaimer p, #ctadisclosure-module--ctadisclaimer p {
            color: #7d7d7d;
            text-align: left;
            padding: 0px 0px 0px;
          }

          #prodimg {
            width: 100%;
          }
        `}</style>

      <title>PDF Editor by PDF.live</title></Helmet>
      <HoneyPage data={homepageData}>
        {disclosure}
      </HoneyPage>
    </HomepageLayout>
  )
}
